<template>
  <div>
    <b-modal
      id="modal-input-status-pulang"
      size="lg"
      centered
      title="Tambah Data Master Status Pulang"
      header-bg-variant="primary"
      header-text-variant="light"
      @hidden="resetModal"
    >
      <b-form>
        <b-form-group label-cols-md="3">
          <template v-slot:label>
            Kode Status Pulang <span class="text-danger">*</span>
          </template>
          <b-form-input
            :state="checkIfValid('kd_status_pulang')"
            type="text"
            v-model="$v.is_data.kd_status_pulang.$model"
          ></b-form-input>
        </b-form-group>
        <b-form-group label-cols-md="3">
          <template v-slot:label>
            Nama Status Pulang <span class="text-danger">*</span>
          </template>
          <b-form-input
            :state="checkIfValid('nm_status_pulang')"
            type="text"
            v-model="$v.is_data.nm_status_pulang.$model"
          ></b-form-input>
        </b-form-group>
        <b-form-group label-cols-md="3">
          <div class="d-flex" style="gap: 20px;">
            <b-form-checkbox
              id="is_rajal"
              v-model="$v.is_data.is_rajal.$model"
              name="checkbox_is_rajal"
              :value="true"
              :unchecked-value="false"
            >
              <!-- :state="checkIfValid('is_rajal')" -->
              <p class="mb-0 p-0" style="margin-top: 2px;">Rawat Jalan</p>
            </b-form-checkbox>
            <b-form-checkbox
              id="is_ranap"
              v-model="$v.is_data.is_ranap.$model"
              name="checkbox_is_ranap"
              :value="true"
              :unchecked-value="false"
            >
              <!-- :state="checkIfValid('is_ranap')" -->
              <p class="mb-0 p-0" style="margin-top: 2px;">Rawat Inap</p>
            </b-form-checkbox>
          </div>
        </b-form-group>
      </b-form>
      <!-- <img :src="src1" /> -->
      <template #modal-footer>
        <b-button variant="secondary" @click="$bvModal.hide('modal-input-status-pulang')">
          Batal
        </b-button>
        <b-button
          variant="primary"
          :disabled="busy || !isValid"
          @click="simpan()"
          >{{ button }}</b-button
        >
      </template>
    </b-modal>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

export default {
  name: "modalInput",
  props:[
  ],
  data() {
    return {
      is_data: {
        kd_status_pulang: "",
        nm_status_pulang: "",
        is_rajal: '0',
        is_ranap: '0',
      },
      src1: "",
      selected: null,
      busy: false,
      button: "Simpan",
    };
  },
  computed: {
    formString() {
      return JSON.stringify(this.is_data, null, 4);
    },
    isValid() {
      return !this.$v.is_data.$invalid;
    },
    isDirty() {
      return this.$v.is_data.$anyDirty;
    },
  },
  mixins: [validationMixin],
  validations: {
    is_data: {
      kd_status_pulang: { required },
      nm_status_pulang: { required },
      is_rajal: { required },
      is_ranap: { required },
    },
  },
  methods: {
    checkIfValid(fieldName) {
      const field = this.$v.is_data[fieldName];
      if (!field.$dirty) {
        return null;
      }
      return !(field.$invalid || field.$model === "");
    },
    simpan() {
      let vm = this;
      vm.busy = true;
      vm.button = "Mohon Tunggu";
      vm.$axios
        .post("/ms_status_pulang/register", {
          ...vm.is_data,
        }).then((res) => {
          if (res.data.message == "sukses") {
            vm.button = "Simpan";
            vm.busy = false;
            vm.$v.$reset();
            vm.$emit("alertFromChild", {
              variant: "success",
              msg: "BERHASIL MENDAFTARKAN MASTER TIPE PANGAN",
              showing: true,
            });
            this.$bvModal.hide("modal-input-status-pulang");
            this.resetModal();
          } else {
            vm.button = "Simpan";
            vm.busy = false;
            vm.$emit("alertFromChild", {
              variant: "danger",
              msg: (res.data.message).toUpperCase(),
              showing: true,
            });
          }
        })
        .catch(() => {
          vm.button = "Simpan";
          vm.busy = false;
          vm.$emit("alertFromChild", {
            variant: "danger",
            msg: "TERJADI KESALAHAN PADA SERVER",
            showing: true,
          });
        });
    },
    resetModal() {
      this.$v.$reset();
      this.is_data = {
        kd_status_pulang: "",
        nm_status_pulang: "",
        is_rajal: '0',
        is_ranap: '0',
      };
    },
  },
};
</script>
